import { joinURL, withBase } from 'ufo'

function urlSafeBase64(value) {
  const base64 = window.btoa(value)

  return base64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

export const getThumb = (image, width, height = 0, resize = 'fill') => {
  if (!image?.file) {
    return ''
  }

  const encodedUrl = urlSafeBase64(image.file)
  const enlarge = 1
  const [x, y] = image.ppoi_tuple
  const gravity = `fp:${x}:${y}`
  const path = `/rs:${resize}:${Math.round(width)}:${Math.round(
    height
  )}:${enlarge}/g:${gravity}/${encodedUrl}`

  return withBase(joinURL('_', path), process.env.REACT_APP_IMGPROXY_URL)
}
