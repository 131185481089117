import React from 'react'

import styles from './Page.module.scss'
import Header from './Header'
import Mirror from './Mirror'
import Introduction from './Introduction'
import Partners from './Partners'
import Creators from './Creators'
import ArticlesAndVideos from './ArticlesAndVideos'
import Footer from './Footer'

function Page() {
  return (
    <>
      <Mirror />

      <div className={styles.container}>
        <div className={styles.hero}>
          <Header className={styles.container} />
          <h1 className={styles.title}>Akcja społeczna Ryzykanci</h1>
          <Introduction />
        </div>

        <ArticlesAndVideos />
        <Creators />
        <Partners />
        <Footer />
      </div>
    </>
  )
}

export default Page
