import React from 'react'

import styles from './Header.module.scss'
import Explainer from './popups/Explainer'
import Support from './popups/Support'
import { ReactComponent as Logo } from '../icons/logo.svg'
import { ReactComponent as GazetaLogo } from '../icons/gazeta.svg'

function Header() {
  return (
    <div className={styles.header}>
      <div className={styles.logos}>
        <Logo className={styles.logo} />
        <GazetaLogo className={styles.subLogo} />
      </div>

      <div className={styles.popups}>
        <Explainer className={styles.explainer} />
        <Support className={styles.support} />
      </div>
    </div>
  )
}

export default Header
