import React from 'react'

import styles from './PlusIcon.module.scss'
import clsx from 'clsx'

function PlusIcon(props) {
  return (
    <div
      className={clsx(
        styles.icon,
        props.white && styles.iconWhite,
        props.rotated && styles.iconRotated,
        props.className
      )}
    />
  )
}

export default PlusIcon
