import React, { useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import WhiteButton from './WhiteButton'
import { useLockBodyScroll } from 'react-use'
import { gsap, SplitText } from '../gsap'

import styles from './ArticlesAndVideos.module.scss'
import BaseImage from './BaseImage'
import ImageFrame from './ImageFrame'
import PlusIcon from './PlusIcon'
import clsx from 'clsx'
import usePaginatedFetch from '../hooks/usePaginatedFetch'
import useGsapContext from '../hooks/useGsapContext'
import { getSplitTextWyswigTargets } from '../helpers'

function ArticlesAndVideos() {
  const [activeItem, setActiveItem] = useState(null)
  const pairs = useRef([])

  const { data, loading, loadMore } = usePaginatedFetch(
    `${process.env.REACT_APP_API_URL}public/pages/home/home-sliders/`,
    7
  )

  useLockBodyScroll(!!activeItem)

  useGsapContext(() => {
    if (loading) return

    pairs.current.forEach(duo => {
      duo.querySelectorAll('.duoElement').forEach(article => {
        const scrollTrigger = {
          trigger: article,
          start: 'top bottom',
          end: 'bottom-=20% center',
          scrub: true,
          once: true
          // markers: true
        }
        const tl = gsap.timeline({ scrollTrigger })

        tl.fromTo(
          article.querySelector('.media'),
          { width: '70%', height: '80%' },
          { width: '100%', height: '100%', duration: 1.5 }
        )

        tl.fromTo(
          article.querySelector('.imageContainer'),
          { padding: 0 },
          { padding: 8 },
          '<'
        )

        tl.fromTo(
          article.querySelector('.decoration'),
          { opacity: 0 },
          { opacity: 1, duration: 0.3 }
        )

        const splitText = new SplitText(
          getSplitTextWyswigTargets(article, 'description'),
          {
            type: 'lines',
            linesClass: 'lineParent'
          }
        )

        tl.fromTo(
          splitText.lines,
          { yPercent: 100, opacity: 0 },
          {
            yPercent: 0,
            opacity: 1,
            stagger: 0.2,
            duration: 1,
            ease: 'power4.out'
          }
        )
      })
    })
  }, [loading])

  const openPopup = item => {
    setActiveItem(item)

    setTimeout(() => {
      const script = document.createElement('script')

      script.src = item.video_url

      const target = document.querySelector(
        `[data-video-article-id="${item.id}"]`
      )
      if (!target) return

      target.appendChild(script)
    }, 0)
  }

  const closePopup = () => setActiveItem(null)

  return data?.results ? (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {data.results.map((item, index) => (
          <div
            className={styles.duo}
            key={item.id}
            ref={el => (pairs.current[index] = el)}
          >
            <article className={clsx('duoElement', styles.videoArticle)}>
              <div
                className={clsx(
                  styles.mediaContainer,
                  styles.mediaVideoContainer
                )}
              >
                <ImageFrame
                  className={clsx('media', styles.media, styles.mediaVideo)}
                >
                  <BaseImage
                    className={styles.image}
                    image={item.video_image}
                    sizes="(min-width: 1280px) 697px, 100vw"
                    imgSizes={[
                      { w: 697, h: 393, resize: 'fill' },
                      { w: 966, h: 551, resize: 'fill' }
                    ]}
                    alt={item.video_image.alt}
                  />
                  <WhiteButton
                    className={clsx(styles.mediaBtn, styles.mediaBtnVideo)}
                    onClick={() => openPopup(item)}
                  >
                    <span className={styles.mediaBtnTextWrapper}>
                      <span className={styles.mediaBtnText}>Zobacz video </span>
                    </span>
                    <PlusIcon className={styles.plusIcon} />
                  </WhiteButton>
                </ImageFrame>
              </div>
              <div className={clsx('decoration', styles.decoration)} />
              <p
                className={clsx('description', styles.descriptionVideo)}
                dangerouslySetInnerHTML={{ __html: item.video_description }}
              />

              {activeItem === item
                ? createPortal(
                    <div
                      className={styles.videoLightbox}
                      data-video-article-id={item.id}
                    >
                      <button
                        type="button"
                        onClick={closePopup}
                        className={styles.videoLightboxCloseBtn}
                      >
                        <PlusIcon
                          rotated
                          white
                          className={styles.videoLightboxCloseBtnIcon}
                        />
                        Zamknij
                      </button>
                    </div>,
                    document.body
                  )
                : null}
            </article>

            <article className={clsx('duoElement', styles.article)}>
              <div
                className={clsx(
                  styles.mediaContainer,
                  styles.mediaArticleContainer
                )}
              >
                <ImageFrame
                  className={clsx('media', styles.media, styles.mediaArticle)}
                >
                  <BaseImage
                    className={styles.image}
                    image={item.article_image}
                    sizes="(min-width: 1280px) 407px, 100vw"
                    imgSizes={[
                      { w: 407, h: 280, resize: 'fill' },
                      { w: 863, h: 587, resize: 'fill' }
                    ]}
                    alt={item.article_image.alt}
                  />
                  <WhiteButton
                    className={clsx(styles.mediaBtn, styles.mediaBtnArticle)}
                    href={item.article_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className={styles.mediaBtnTextWrapper}>
                      <span className={styles.mediaBtnText}>Przeczytaj </span>
                    </span>
                    <PlusIcon className={styles.plusIcon} />
                  </WhiteButton>
                </ImageFrame>
              </div>

              <div className={clsx('decoration', styles.decoration)} />
              <p
                className={clsx('description', styles.descriptionArticle)}
                dangerouslySetInnerHTML={{ __html: item.article_description }}
              />
            </article>
          </div>
        ))}
      </div>

      {data.next ? (
        <WhiteButton
          className={styles.paginationBtn}
          disabled={loading}
          onClick={loadMore}
        >
          Wczytaj więcej
        </WhiteButton>
      ) : null}
    </div>
  ) : null
}

export default ArticlesAndVideos
