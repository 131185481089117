import React, { useMemo } from 'react'
import { getThumb } from '../imgproxy'

function BaseImage(props) {
  const { image, imgSizes, alt = '', sizes, ...rest } = props

  const srcset = useMemo(() => {
    const srcs = []

    imgSizes.forEach(size => {
      const w = size.w
      const h = size.h
      srcs.push(`${getThumb(image, w, h, size.resize)} ${w}w`)
      srcs.push(`${getThumb(image, 2 * w, 2 * h, size.resize)} ${2 * w}w`)
    })

    return srcs.join(',')
  }, [image, imgSizes])

  return (
    <img srcSet={srcset} alt={alt} sizes={sizes} loading="lazy" {...rest} />
  )
}

export default BaseImage
