import React from 'react'

import styles from './Footer.module.scss'

function Footer() {
  const year = new Date().getFullYear()

  return (
    <footer className={styles.footer}>
      <div className={styles.company}>
        © {year} Copyright Gazeta.pl sp. z o.o.
      </div>

      <ul className={styles.linksList}>
        <li className={styles.linksListItem}>
          <a
            href="https://pomoc.gazeta.pl/pomoc/7,154322,8856779,ochrona-prywatnosci.html#e=AFootLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            Polityka Prywatności
          </a>
        </li>
        <li className={styles.linksListItem}>
          <a
            href="https://pomoc.gazeta.pl/pomoc/7,154322,12911765,zasady-korzystania-z-serwisow-internetowych-agory-sa.html#e=AFootLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            Zasady korzystania w&nbsp;portalu
          </a>
        </li>
        {/* Code directly copied as requested by client */}
        <li
          className={styles.linksListItem}
          onClick={() => window.OneTrust.ToggleInfoDisplay()}
        >
          <a href="javascript:void(0)">Ustawienia Prywatności</a>
        </li>
      </ul>
    </footer>
  )
}

export default Footer
