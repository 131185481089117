import React, { useState } from 'react'
import { Collapse } from 'react-collapse'

import PlusIcon from '../PlusIcon'
import WhiteButton from '../WhiteButton'
import styles from './Support.module.scss'
import clsx from 'clsx'

function Support(props) {
  const [isRevealed, setRevealed] = useState(false)

  const toggleReveal = () => setRevealed(!isRevealed)

  return (
    <div
      className={clsx(
        props.className,
        styles.support,
        isRevealed && styles.supportRevealed
      )}
    >
      <button
        type="button"
        className={styles.supportBtn}
        onClick={toggleReveal}
      >
        <span id="buttonBg" className={styles.buttonBg} />
        <span className={styles.innerText}>
          Potrzebujesz wsparcia? <PlusIcon className={styles.plusIcon} />
        </span>
      </button>

      <Collapse isOpened={isRevealed}>
        <div className={styles.content}>
          <h3 className={styles.header}>
            Potrzebujesz wsparcia
            <br /> w&nbsp;kryzysie zdrowia psychicznego?
          </h3>
          <p className={styles.description}>
            Zobacz jak umówić się
            <strong>
              {' '}
              na&nbsp;bezpłatną
              <br /> konsultację ze&nbsp;specjalistą.
            </strong>
          </p>

          <WhiteButton
            href="https://thepresja.pl/konsultacje/"
            className={styles.moreLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            DOWIEDZ SIĘ WIĘCEJ
          </WhiteButton>

          <p className={styles.partner}>
            Wsparcie zapewnia partner akcji:
            <img
              className={styles.partnerImage}
              srcSet="/images/thepresja.png 150w, /images/thepresja@2x.png 300w"
              sizes="123px"
              alt=""
            />
          </p>

          <button
            type="button"
            className={styles.closeBtn}
            onClick={toggleReveal}
          >
            <span className={styles.closeBtnText}>Zamknij</span>
            <PlusIcon rotated />
          </button>
        </div>
      </Collapse>
    </div>
  )
}

export default Support
