import React, { Suspense } from 'react'
import { useWindowSize } from 'react-use'
import { Canvas } from 'react-three-fiber'
import { Html } from '@react-three/drei'

import styles from './Mirror.module.scss'
import Scene1 from '../mirror/Scene1'

function Mirror() {
  const { width } = useWindowSize()

  const isTablet = width >= 768
  const isDesktop = width >= 1280

  const mobilePositionZ = 10 * (375 / width)
  const tabletPositionZ = 2 * (768 / width)
  const desktopPositionZ = 5

  return (
    <>
      <img
        className={styles.background}
        srcSet="/images/mirror-background.png 960w, /images/mirror-background@2x.png 1920w"
        sizes="100vw"
        alt=""
      />

      <Canvas
        key={width}
        concurrent
        shadowMap
        pixelRatio={window.devicePixelRatio}
        camera={{
          position: [
            0,
            0,
            isDesktop
              ? desktopPositionZ
              : isTablet
              ? tabletPositionZ
              : mobilePositionZ
          ],
          fov: 70
        }}
        className={styles.canvas}
      >
        <Suspense fallback={<Html />}>
          <Scene1 />
        </Suspense>
        <ambientLight intensity={0.4} />
      </Canvas>
    </>
  )
}

export default Mirror
