//ryzykanci
import { render } from 'react-dom'
import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Page from './components/Page'

import './scss/main.scss'

function Body() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Page />
        </Route>
      </Switch>
    </Router>
  )
}

render(<Body />, document.querySelector('#root'))
