import useFetch from 'use-http'
import { useState } from 'react'

function usePaginatedFetch(url, pageSize = 1) {
  const [page, setPage] = useState(1)
  const { data, loading } = useFetch(
    `${url}?page_size=${pageSize}&page=${page}`,
    {
      onNewData: (currentRes, newRes) => ({
        ...newRes,
        results: [...(currentRes?.results || []), ...newRes.results]
      }),
      credentials: 'omit'
    },
    [page]
  )

  const loadMore = () => setPage(page + 1)

  return {
    data,
    loading,
    loadMore
  }
}

export default usePaginatedFetch
