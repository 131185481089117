import React from 'react'
import clsx from 'clsx'

import { ReactComponent as FrameLeft } from '../icons/image-frame-left.svg'
import { ReactComponent as FrameRight } from '../icons/image-frame-right.svg'
import styles from './ImageFrame.module.scss'

function ImageFrame(props) {
  const { children, className } = props

  return (
    <div className={clsx(styles.imageWrapper, className)}>
      <FrameLeft
        className={clsx(
          styles.imageFrame,
          styles.imageFrameLeft,
          'imageFrameLeft'
        )}
      />
      <FrameRight
        className={clsx(
          styles.imageFrame,
          styles.imageFrameRight,
          'imageFrameRight'
        )}
      />
      <div className={clsx('imageContainer', styles.imageContainer)}>
        <div className={styles.imageContainerChild}>{children}</div>
      </div>
    </div>
  )
}

export default ImageFrame
