import { useCallback, useEffect, useRef } from 'react'
import { useWindowSize } from 'react-use'
import { gsap } from '../gsap'

function useGsapContext(contextFunction, deps = []) {
  const context = useRef(null)
  const { width } = useWindowSize()

  const contextFunctionCallback = useCallback(contextFunction, deps)

  useEffect(() => {
    const timeout = setTimeout(() => {
      context.current = gsap.context(contextFunctionCallback)
    }, 100)

    return () => {
      if (context.current) context.current.revert()
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextFunctionCallback, width, ...deps])

  return { context }
}

export default useGsapContext
