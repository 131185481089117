import React, { useRef, useState } from 'react'

import styles from './Introduction.module.scss'
import { ReactComponent as ArrowSide } from '../icons/arrow-side.svg'
import { gsap, SplitText } from '../gsap'
import useGsapContext from '../hooks/useGsapContext'
import clsx from 'clsx'

function Introduction() {
  const leftColumnText = useRef(null)
  const rightColumnText = useRef(null)
  const moreBtnText = useRef(null)
  const moreBtnUnderline = useRef(null)
  const moreBtnArrow = useRef(null)
  const [isGsapActive, setGsapActive] = useState(false)

  const onMoreBtnClick = () => window.scrollTo(0, window.innerHeight)

  useGsapContext(() => setGsapActive(true))

  // Left column text
  useGsapContext(() => {
    const splitText = new SplitText(leftColumnText.current, {
      type: 'lines',
      linesClass: 'lineChild'
    })
    new SplitText(leftColumnText.current, {
      type: 'lines',
      linesClass: 'lineParent'
    })

    gsap.fromTo(
      splitText.lines,
      {
        yPercent: 100
      },
      {
        yPercent: 0,
        stagger: 0.1,
        duration: 1,
        delay: 1,
        ease: 'power4.out'
      }
    )
  })

  // Right column text
  useGsapContext(() => {
    const splitText = new SplitText(rightColumnText.current, {
      type: 'lines',
      linesClass: 'lineParent'
    })

    gsap.fromTo(
      splitText.lines,
      {
        yPercent: 100,
        opacity: 0
      },
      {
        yPercent: 0,
        opacity: 1,
        stagger: 0.2,
        duration: 1,
        delay: 1,
        ease: 'power4.out'
      }
    )
  })

  // More button
  useGsapContext(() => {
    gsap.fromTo(
      moreBtnText.current,
      {
        color: 'transparent'
      },
      {
        color: 'white',
        duration: 1.5,
        delay: 1,
        ease: 'power4.out'
      }
    )

    gsap.fromTo(
      moreBtnUnderline.current,
      {
        width: '0%'
      },
      {
        width: '100%',
        duration: 1.5,
        delay: 1,
        ease: 'power4.out'
      }
    )

    gsap.fromTo(
      moreBtnArrow.current,
      {
        scale: 0,
        rotate: -90
      },
      {
        scale: 1,
        rotate: 0,
        duration: 1,
        delay: 1,
        ease: 'power4.out'
      }
    )
  })

  return (
    <div className={clsx(!isGsapActive && styles.hidden, styles.introduction)}>
      <p className={styles.leftColumn} ref={leftColumnText}>
        <span className={styles.gradientText}>Ryzyko</span> – jak wielu mężczyzn
        chce się z nim kojarzyć? A z byciem twardzielem? Pytanie brzmi: co
        dalej?
      </p>

      <div className={styles.rightColumn}>
        <p className="rightColumnText" ref={rightColumnText}>
          <span className={styles.gradientText}>Męska depresja</span> ma się
          dobrze właśnie przez stereotypy, że facet musi poradzić sobie sam - w
          pracy, w relacjach, zawsze, wszędzie.
          <br />
          <br />
          <span className={styles.gradientText}>
            Zdrowie psychiczne to nie gra i nie program.
          </span>
          <br />
          <span className={styles.gradientText}>
            Ryzykanci to nowa kampania społeczna Gazeta.pl
          </span>{' '}
          o zdrowiu psychicznym mężczyzn. Poznaj ryzykantów, którzy opowiadają
          Ci swoje historie.
        </p>
        <button
          type="button"
          className={styles.moreBtn}
          onClick={onMoreBtnClick}
        >
          <span className={styles.moreBtnText} ref={moreBtnText}>
            Dowiedz się więcej
            <div className={styles.moreBtnUnderline} ref={moreBtnUnderline} />
          </span>{' '}
          <ArrowSide ref={moreBtnArrow} />
        </button>
      </div>
    </div>
  )
}

export default Introduction
