import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import head from 'lodash/head'
import last from 'lodash/last'
import countBy from 'lodash/countBy'
import entries from 'lodash/entries'
import maxBy from 'lodash/maxBy'
import flow from 'lodash/flow'
import partialRight from 'lodash/partialRight'

function useContentIds() {
  const { search } = useLocation()
  const { contentId } = queryString.parse(search)

  const ids = (
    contentId ? (Array.isArray(contentId) ? contentId : [contentId]) : []
  ).map(id => Number(id))
  const idsWithoutZero = ids.filter(id => id !== 0)
  const idsWithZero = ids.filter(id => id === 0)

  const areContentIdsUnique =
    new Set(idsWithoutZero).size === idsWithoutZero.length

  const areZeroesDominant = idsWithZero.length / ids.length > 0.5

  const firstOrDominantContentId = areContentIdsUnique
    ? idsWithoutZero[0]
    : flow(countBy, entries, partialRight(maxBy, last), head)(idsWithoutZero)

  const contentIdToShow = areZeroesDominant ? null : firstOrDominantContentId

  const shouldShowExplainer = typeof contentIdToShow === 'number'

  return { contentIdToShow, shouldShowExplainer }
}

export default useContentIds
