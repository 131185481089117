import React from 'react'

import styles from './WhiteButton.module.scss'
import clsx from 'clsx'

function WhiteButton(props) {
  const { children, className, small, ...rest } = props

  const Component = 'href' in rest ? 'a' : 'button'

  return (
    <Component
      type="button"
      className={clsx(styles.btn, small && styles.btnSmall, className)}
      {...rest}
    >
      <span className="background" />
      {children}
    </Component>
  )
}

export default WhiteButton
