import React, { useState } from 'react'

import PlusIcon from '../PlusIcon'
import styles from './Explainer.module.scss'
import clsx from 'clsx'
import useContentIds from '../../hooks/useContentIds'

function Explainer(props) {
  let { shouldShowExplainer } = useContentIds()
  const [isHidden, setHidden] = useState(false)

  return isHidden || !shouldShowExplainer ? null : (
    <div className={clsx(styles.explainer, props.className)}>
      <img
        className={styles.image}
        srcSet="/images/chajzer-explainer.png 323w, /images/chajzer-explainer@2x.png 646w"
        sizes="(min-width: 1280px) 342px, 204px"
        alt=""
      />

      <div className={styles.contentWrap}>
        <div className={styles.content}>
          <h3 className={styles.header}>
            Możesz być w&nbsp;grupie ryzyka męskiej depresji.
          </h3>
          <p className={styles.description}>
            Zobacz, co się z&nbsp;tym wiąże{' '}
            <strong>i&nbsp;co&nbsp;możesz zrobić.</strong>
          </p>

          <button
            type="button"
            className={styles.closeBtn}
            onClick={() => setHidden(true)}
          >
            <span className={styles.closeBtnText}>Zamknij</span>
            <PlusIcon className={styles.closeBtnIcon} rotated />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Explainer
