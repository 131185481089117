import React, { useRef } from 'react'
import { useMedia } from 'react-use'
import { gsap, SplitText } from '../gsap'

import styles from './Creators.module.scss'
import BaseImage from './BaseImage'
import ImageFrame from './ImageFrame'
import WhiteButton from './WhiteButton'
import usePaginatedFetch from '../hooks/usePaginatedFetch'
import useGsapContext from '../hooks/useGsapContext'
import clsx from 'clsx'
import { getSplitTextWyswigTargets } from '../helpers'

function Creators() {
  const cards = useRef([])
  const isDesktop = useMedia('(min-width: 1280px)')
  const { data, loading, loadMore } = usePaginatedFetch(
    `${process.env.REACT_APP_API_URL}public/pages/home/creator-sliders/`,
    isDesktop ? 12 : 3
  )

  useGsapContext(() => {
    if (loading) return

    cards.current.forEach(card => {
      const scrollTrigger = {
        trigger: card,
        start: 'top bottom',
        end: 'bottom-=20% center',
        scrub: true,
        once: true
        // markers: true
      }
      const tl = gsap.timeline({ scrollTrigger })

      tl.fromTo(
        card.querySelector('.media'),
        { width: '80%', height: '80%' },
        { width: '100%', height: '100%' }
      )

      tl.fromTo(
        card.querySelector('.imageContainer'),
        { padding: 0 },
        { padding: 8 },
        '<'
      )

      const splitText = new SplitText(
        getSplitTextWyswigTargets(card, 'creatorName'),
        {
          type: 'lines',
          linesClass: 'lineParent'
        }
      )

      tl.fromTo(
        splitText.lines,
        { yPercent: 100, opacity: 0 },
        {
          yPercent: 0,
          opacity: 1,
          stagger: 0.2,
          duration: 1,
          ease: 'power4.out'
        }
      )
    })
  }, [loading, data])

  return data?.results ? (
    <section className={styles.creators}>
      <h2 className={styles.title}>{data.creators_header}</h2>

      <div className={styles.creatorCards}>
        {data.results.map((item, index) => (
          <figure
            className={styles.creatorCard}
            key={item.id}
            ref={el => (cards.current[index] = el)}
          >
            <div className={styles.mediaContainer}>
              <ImageFrame className={clsx('media', styles.imageWrapper)}>
                <BaseImage
                  className={styles.image}
                  image={item.image}
                  sizes="(min-width: 1280px) 250px, (min-width: 768px) 420px, 266px"
                  imgSizes={[
                    { w: 266, h: 266, resize: 'fill' },
                    { w: 420, h: 420, resize: 'fill' }
                  ]}
                  alt={item.image.alt}
                />
              </ImageFrame>
            </div>

            <figcaption
              className={clsx('creatorName', styles.creatorName)}
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
          </figure>
        ))}
      </div>

      {data.next ? (
        <WhiteButton
          className={styles.paginationBtn}
          disabled={loading}
          onClick={loadMore}
        >
          Wczytaj więcej
        </WhiteButton>
      ) : null}
    </section>
  ) : null
}

export default Creators
