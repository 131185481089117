import React from 'react'

import styles from './Partners.module.scss'

function Partners() {
  const logos = [
    {
      file: 'THEPRESJA.png',
      alt: 'gazeta.pl',
      width: 159,
      height: 70
    },
    {
      file: 'GAZETA.svg',
      alt: 'the presja',
      width: 194,
      height: 45
    },
    {
      file: 'PP.svg',
      alt: 'Panowie Programiści',
      width: 168,
      height: 45
    },
    {
      file: 'TWIN.svg',
      alt: 'Twin',
      width: 115,
      height: 35
    }
  ]

  return (
    <section className={styles.partners}>
      <h2 className={styles.title}>Partnerzy akcji:</h2>

      <div className={styles.logos}>
        {logos.map((logo, index) => (
          <img
            className={styles.logo}
            key={index}
            src={`/logos/${logo.file}`}
            alt=""
            loading="lazy"
            height={logo.height}
            width={logo.width}
          />
        ))}
      </div>
    </section>
  )
}

export default Partners
